@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,700);
  /* http://meyerweb.com/eric/tools/css/reset/
     v2.0-modified | 20110126
     License: none (public domain)
  */

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
}


/* make sure to set some focus styles for accessibility */
:focus {
  outline: 0;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none;
}

input[type=search] {
  -webkit-appearance: none;
  -moz-appearance: none;
  box-sizing: content-box;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */

audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  max-width: 100%;
}

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */

audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */

[hidden] {
  display: none;
}

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */

html {
  font-size: 100%; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
  -ms-text-size-adjust: 100%; /* 2 */
}

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */

a:focus {
  outline: thin dotted;
}

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */

a:active,
a:hover {
  outline: 0;
}

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */

img {
  border: 0; /* 1 */
  -ms-interpolation-mode: bicubic; /* 2 */
}

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */

figure {
  margin: 0;
}

/**
 * Correct margin displayed oddly in IE 6/7.
 */

form {
  margin: 0;
}

/**
 * Define consistent border, margin, and padding.
 */

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */

legend {
  border: 0; /* 1 */
  padding: 0;
  white-space: normal; /* 2 */
  *margin-left: -7px; /* 3 */
}

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */

button,
input,
select,
textarea {
  font-size: 100%; /* 1 */
  margin: 0; /* 2 */
  vertical-align: baseline; /* 3 */
  *vertical-align: middle; /* 3 */
}

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */

button,
input {
  line-height: normal;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */

button,
select {
  text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */

button,
html input[type="button"], /* 1 */
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button; /* 2 */
  cursor: pointer; /* 3 */
  *overflow: visible;  /* 4 */
}

/**
 * Re-set default cursor for disabled elements.
 */

button[disabled],
html input[disabled] {
  cursor: default;
}

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
  *height: 13px; /* 3 */
  *width: 13px; /* 3 */
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */

input[type="search"] {
  -webkit-appearance: textfield; /* 1 */ /* 2 */
  box-sizing: content-box;
}

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * Remove inner padding and border in Firefox 3+.
 */

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */

textarea {
  overflow: auto; /* 1 */
  vertical-align: top; /* 2 */
}

/**
 * Remove most spacing between table cells.
 */

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html,
button,
input,
select,
textarea {
  color: #222;
}


::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

img {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

.chromeframe {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}
* {
  font-family: "Roboto", sans-serif !important;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.root {
  background: #ececec;
  min-height: 100vh;
}

.container {
  width: 100%;
  background: #ffffff;
  max-width: 1920px;
  margin: 0 auto;
  padding: 24px 32px;
}
@media (max-width: 640px) {
  .container {
    padding: 12px 16px;
  }
}

@media (min-width: 769px) {
  .content {
    min-height: calc(100vh - 125px);
  }
}

.closeButton {
  position: absolute;
  right: 8px;
  top: 4px;
  background: transparent;
  color: #4a4a4a;
  font-weight: 700;
  border: 0;
  padding: 0;
  width: 20px;
  height: 20px;
}

.primaryButton {
  background: #F15A29;
  border: 0;
  box-sizing: border-box;
  border-radius: 5px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 1em;
  padding: 0.5em 1.5em;
  color: #FFFFFF;
}
.primaryButton:disabled {
  opacity: 0.5;
}

.secondaryButton {
  background: #ffffff;
  border: 1px solid #F15A29;
  box-sizing: border-box;
  border-radius: 5px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 1em;
  padding: 0.25em 0.5em;
  color: #F15A29;
}
.secondaryButton:hover {
  background: #FF7B45;
  color: #ffffff;
}
.secondaryButton:disabled {
  opacity: 0.5;
}
.secondaryButton:not(:first-of-type) {
  margin-left: 0.5rem;
}

.adminWeb__notFound {
  font-size: 2em;
  font-weight: 300;
  color: #333333;
  padding: 1em 0;
}
.adminWeb__radio__input {
  display: none;
}
.adminWeb__radio__input:checked + .adminWeb__radio__label:before {
  background-color: #F15A29;
  box-shadow: inset 0 0 0 3px #fff;
}
.adminWeb__radio__label {
  display: flex;
  align-items: flex-end;
  position: relative;
  font-size: 0.75em;
  text-transform: uppercase;
}
.adminWeb__radio__label:before {
  content: "";
  background: #fff;
  border-radius: 100%;
  border: 1.5px solid #F15A29;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 4px;
  cursor: pointer;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
.adminWeb__table {
  width: 100%;
  margin-top: 2em;
}
.adminWeb__table th {
  text-align: left;
  border: 1px solid #808080;
  font-size: 0.875em;
  padding: 0.5em 1em;
}
.adminWeb__table td {
  border: 0.25px solid rgba(128, 128, 128, 0.5);
  font-size: 0.875em;
  padding: 0.5em 1em;
}
.adminWeb__table tbody .actions > br {
  display: none;
}
@media (min-width: 641px) {
  .adminWeb__table__header {
    display: none;
  }
  .adminWeb__table .active {
    background: #f9f9f9;
  }
}
@media (min-width: 641px) and (max-width: 960px) {
  .adminWeb__table tbody .actions > br {
    display: block;
  }
  .adminWeb__table tbody .actions .secondaryButton {
    width: 100%;
  }
  .adminWeb__table tbody .actions .secondaryButton:not(:first-of-type) {
    margin-top: 0.5em;
    margin-left: 0;
  }
}
@media (max-width: 640px) {
  .adminWeb__table thead {
    display: none;
  }
  .adminWeb__table tr {
    display: flex;
    flex-wrap: wrap;
    padding: 1em 0;
  }
  .adminWeb__table tr:first-of-type {
    padding-top: 0;
  }
  .adminWeb__table tr:not(:first-of-type) {
    border-top: 1px solid rgba(0, 0, 0, 0.25);
  }
  .adminWeb__table tr .fullWidth {
    width: 100%;
  }
  .adminWeb__table tr .halfWidth {
    width: 50%;
  }
  .adminWeb__table tr > td {
    padding: 0.5em 0.25em;
    border: 0;
  }
  .adminWeb__table__header {
    font-size: 0.7em;
    color: #7C7C7C;
    margin-bottom: 0.5em;
  }
}
@media (max-width: 240px) {
  .adminWeb__table tbody {
    font-size: 14px;
  }
}
@media (min-width: 961px) {
  .adminWeb__table.lockers th:nth-of-type(1), .adminWeb__table.lockers th:nth-of-type(3), .adminWeb__table.lockers th:nth-of-type(6), .adminWeb__table.lockers td:nth-of-type(1), .adminWeb__table.lockers td:nth-of-type(3), .adminWeb__table.lockers td:nth-of-type(6) {
    width: 5%;
  }
  .adminWeb__table.lockers th:nth-of-type(2), .adminWeb__table.lockers td:nth-of-type(2) {
    width: 10%;
  }
}
.adminWeb__spoiler {
  background: #F7F7F7;
  padding: 0 1rem;
  margin-bottom: 1.5rem;
  width: 100%;
  height: 40px;
  overflow: hidden;
  position: relative;
}
.adminWeb__spoiler.open {
  height: auto;
}
.adminWeb__spoiler__btn {
  width: 100%;
  padding: 0.5rem 0;
  border: 0;
  background: transparent;
}

[data-vspace="12px"] > *:not(:first-child) {
  margin-top: 12px;
}

[data-vspace="16px"] > *:not(:first-child) {
  margin-top: 16px;
}

[data-vspace="24px"] > *:not(:first-child) {
  margin-top: 24px;
}

[data-vspace="36px"] > *:not(:first-child) {
  margin-top: 36px;
}

[data-hspace="8px"] > *:not(:first-child) {
  margin-left: 8px;
}

[data-hspace="12px"] > *:not(:first-child) {
  margin-left: 12px;
}

[data-hspace="36px"] > *:not(:first-child) {
  margin-left: 36px;
}

[data-margin-top="32px"] {
  margin-top: 32px;
}

@media (max-width: 280px) {
  [data-display=flex] {
    flex-direction: column;
  }

  [data-hspace="12px"] > *:not(:first-child) {
    margin-left: 0;
  }

  [data-hspace="8px"] > *:not(:first-child) {
    margin-left: 0;
  }
}
[data-display=flex] {
  display: flex;
}

[data-wrap=wrap] {
  flex-wrap: wrap;
}

[data-direction=column] {
  flex-direction: column;
}

[data-justify=start] {
  justify-content: flex-start;
}

[data-justify=end] {
  justify-content: flex-end;
}

[data-justify=center] {
  justify-content: center;
}

[data-justify=between] {
  justify-content: space-between;
}

[data-justify=around] {
  justify-content: space-around;
}

[data-align=start] {
  align-items: flex-start;
}

[data-align=center] {
  align-items: center;
}

[data-align=end] {
  align-items: flex-end;
}

[data-width=full] {
  width: 100%;
}

[data-width=half] {
  width: 50%;
}

/*# sourceMappingURL=index.css.map */

.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 9;
  margin: -30px 0 0 -30px;
  border: 8px solid #ff78782e;
  border-left: 8px solid #db2000;
  border-top: 8px solid #db2000;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  -webkit-animation: spin 1s linear infinite;
          animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/*# sourceMappingURL=input.support.auth.style.css.mapss.map */

